
import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/components/Modal/Modal.vue'
import { RegisterSongService } from '@/services/RegisterSongService';
import RegisterSongStep2EnterLyrics from '@/views/IntellectualProperty/RegisterSongs/RegisterSongStep2EnterLyrics.vue';
import RegisterSongStep4Done from '@/views/IntellectualProperty/RegisterSongs/RegisterSongStep4Done.vue';
import { RequestType } from '@/configs/SongRegistrationConfig';
import { BusyService } from '@/services/BusyService';
import { ToastService } from '@/services/ToastService';
import { bus, i18n } from '@/main';
import EditSongChordsModal from './EditSongChordsModal.vue';
import { FELyricPart } from '@/models/frontendOnly/FELyricPart';
import { cloneDeep } from 'lodash';


@Component({
  components:{
    Modal,
    RegisterSongStep2EnterLyrics,
    RegisterSongStep4Done
  }
})
export default class EditSongLyricsModal extends Vue {
  init = false;
  store = RegisterSongService;
  completed = false;
  originalLyrics: FELyricPart[] = [];

  @Prop({ required: true})
  editChordsRef!: EditSongChordsModal;

  async show(skipLoad = false) {
    if (!skipLoad){
      BusyService.showBusy();
      await RegisterSongService.loadEditSong(
        this.$route.params.ccliSongId,
        RequestType.EditLyrics,
        this.onClose,
      );
      BusyService.hideBusy();
      this.originalLyrics = cloneDeep(RegisterSongService.data!.lyrictext);
    }
    this.init = true;
    this.completed = false;
    if ((this.$refs.comp as RegisterSongStep2EnterLyrics)){
      (this.$refs.comp as RegisterSongStep2EnterLyrics).restrictedWordsError = false;
    }

    (this.$refs.modal as Modal).show();
  }

  get requireEditChordsAfter() {
    return !!this.store.data?.chords && this.store.lyricsDiffer;
  }

  onClose(){
    (this.$refs.modal as Modal).hide();
    this.init = false;
  }
  async onSave(){
    if (!this.store.data){
      return
    }
    this.store.requireEditChordsAfter = this.requireEditChordsAfter;
    if (this.requireEditChordsAfter){
      (this.$refs.modal as Modal).hide();
      this.editChordsRef.editSongChordsAfterLyricEdits()
      return;
    }

    try{
        await RegisterSongService.save();
        ToastService.successToast(this.$t('R.MES_SongChangesSaved').toString());
        bus.$emit('refreshSong');
        this.completed = true;
        this.init = false;

    }catch(err){
      if (this.$refs.comp){
        (this.$refs.comp as RegisterSongStep2EnterLyrics).handleError(err);
      }else{
        console.error(err);
        ToastService.dangerToast(i18n.t('R.MES_ServerError').toString());
      }
    }
    (this.$refs.modal as Modal).scrollToTop();
  }

}
