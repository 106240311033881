
import { bus, i18n } from '@/main';
import { Component, Prop, Vue } from 'vue-property-decorator'
import Table from '@/components/Table/Table.vue';
import Modal from '@/components/Modal/Modal.vue';
import LargeIcon from '@/components/Icon/LargeIcon.vue';
import FButton from '@/components/Button/FButton.vue';
import { TableColumn } from '@/models/TableColumn';
import { sum } from 'lodash';
import { SongDetails as SongDetailsModel } from '@/models/SongDetails';
import { BusyService } from '@/services/BusyService';
import { ApiService } from '@/services/ApiService';
import { TerritoryService } from '@/services/TerritoryService';
import EditSongDetailsModal from './EditSongDetailsModal.vue';
import EditSongLyricsModal from './EditSongLyricsModal.vue';
import AddSongLyricsModal from './AddSongLyricsModal.vue';
import SongChordEditorModal from './SongChordEditorModal.vue';
import EditSongOwnershipModal from './EditSongOwnershipModal.vue';
import { DynamicTranslationService } from '@/services/DynamicTranslationService';
import { ProfileService } from '@/services/ProfileService';
import ChordProPreview from '@/components/ChordProEditor/ChordProPreview.vue';
import { FELyricPart } from '@/models/frontendOnly/FELyricPart';
import { SongWriterDetails } from '@/models/SongWriterDetails';
import EditSongChordsModal from './EditSongChordsModal.vue';
import SongDetailsEditButton from './SongDetailsEditButton.vue';
import { GTMService } from '@/services/GTMService';
import { RegisterSongService } from '@/services/RegisterSongService';
let previousSearch = '';


@Component({
  metaInfo: () => ({
    title: DynamicTranslationService.songDetailsTitle
  }),
  components:{
    Table,
    LargeIcon,
    Modal,
    FButton,
    EditSongDetailsModal,
    EditSongLyricsModal,
    AddSongLyricsModal,
    EditSongOwnershipModal,
    SongChordEditorModal,
    ChordProPreview,
    EditSongChordsModal,
    SongDetailsEditButton,
  },
  beforeRouteEnter: (to, from, next) => {
    previousSearch = from.query.s as string || '';
    next();
  }
})
export default class SongDetails extends Vue {

  @Prop({ required: true})
  ccliSongId!: string;
  song: SongDetailsModel | null = null;
  loading = false;

  get featureToggleChords() {
    return ProfileService.profile?.featureToggleChords || false;
  }

  contactInfo = {
    name: '',
    website: '',
    phone: ''
  }
  edit = false;

  get prevSearch() {
    return previousSearch;
  }

  mounted() {
    this.getSong();
    bus.$on('refreshSong', this.getSong);
    bus.$on('territoryUpdated', this.getSong);
  }

  beforeDestroy() {
    bus.$off('refreshSong', this.getSong);
    bus.$off('territoryUpdated');
  }

  viewContactInfo(data:{
    name: string;
    phone: string;
    website: string;
  }){
    this.contactInfo.name = data.name;
    this.contactInfo.phone = data.phone;
    this.contactInfo.website = data.website;
    (this.$refs.modal as Modal).show();
  }

  get ownershipTotal() {
    return Math.round(sum(this.song?.ownership.map(o => o.percentage))*100)/100;
  }

  get ownershipUnclaimed() {
    return Math.round((100 - this.ownershipTotal)*100)/100;
  }

  get ownershipColumns(): TableColumn[] {
    return [
      {
        key: 'catalog',
        label: i18n.t('G.LIT_Catalog').toString()
      },
      {
        key: 'administrator',
        label: i18n.t('R.LIT_SongAdministrator').toString()
      },
      {
        key: 'percentage',
        label: i18n.t('R.LIT_SongOwnershipPercentage').toString()
      }
    ]
  }

  async getSong(){
    this.loading = true;
    BusyService.showBusy();
    try{
      this.song = await ApiService.post('/api/GetSongDetails', {
        songNumber: parseInt(this.ccliSongId),
        territoryId: parseInt(TerritoryService.territoryId)
      })

      BusyService.hideBusy();
    }catch(error){
      this.loading = false;
      BusyService.hideBusy();
      throw(error)
    }
  }

  async onEdit() {
    this.edit = true;
    await this.$nextTick();
    (this.$refs.editModal as Modal).show();
  }

  async closeEditModal(){
    (this.$refs.editModal as Modal).hide();
    await this.$nextTick();
    this.edit = false;
  }

  editSongChords(){
    if (!this.song){
      return;
    }

    const chords = RegisterSongService.removeOverwrittenDirectives(this.song.chords);
    const lyrics: FELyricPart[] = this.createFELyricsParts();

    GTMService.pushCustomEvent({event: 'songDetailsEditChords'});
    (this.$refs.editChordsModal as EditSongChordsModal).editSongChords(this.song.title, chords, this.song.contributors.join(', '),lyrics, false);
  }

  createFELyricsParts(){
    if (!this.song){
      return [];
    }
    const lyrics: FELyricPart[] = this.song.lyrics.map(l => ({
        LyricPartType: l.name,
        LyricPartTextAll: l.lines.split('|').map(line => line.trim()).join('\n'),
        LyricPartId: '',
        LyricPartTypeLid: '',
        LyricPartCounter: l.stanzaNumber,
        LyricPartText: [l.lines],
        validationErrors: [] as string[]
      }));
      return lyrics;
  }

  addSongChords() {
    if (!this.song){
      return;
    }

    GTMService.pushCustomEvent({event: 'songDetailsAddChords'});
    const lyrics: FELyricPart[] = this.createFELyricsParts();

    const songwriters: SongWriterDetails[] = this.song.contributors.map(c => ({
      displayName: c,
      partyId: '',
      wroteWords: true,
      wroteMusic: true,
      originallyFromSpi: false,
    }));

    (this.$refs.editChordsModal as EditSongChordsModal).addSongChords(this.song.title, lyrics, songwriters, false);
  }

  addSongLyrics() {
    GTMService.pushCustomEvent({event: 'songDetailsAddLyrics'});
    (this.$refs.addSongLyricsModal as AddSongLyricsModal).show();
  }
  editSongDetails() {
    GTMService.pushCustomEvent({event: 'songDetailsEditDetails'});
    (this.$refs.editSongDetailsModal as EditSongDetailsModal).show();
  }
  editSongLyrics() {
    GTMService.pushCustomEvent({event: 'songDetailsEditLyrics'});
    (this.$refs.editSongLyricsModal as EditSongLyricsModal).show();
  }
  editSongOwnership() {
    GTMService.pushCustomEvent({event: 'songDetailsEditOwnership'});
    (this.$refs.editSongOwnershipModal as EditSongOwnershipModal).show();
  }
  formatLines(lines: string){
    return lines.replaceAll('|', '<br>');
  }

}
